import React, { Component } from "react";
import GoArrow from "../../assets/icon-arrow.svg";
import styled from "styled-components";
import styles from "./careersTable.module.less";
import { Button } from "react-bootstrap";

const TableDiv = styled.div`
  tr {
    border-top: 0;
    border-bottom: 1px solid #ededed;
  }
  td {
    padding: 20px 60px 20px 10px;
    cursor: pointer;
    position: relative;
  }
  th {
    padding: 25px 10px;
    font-weight: 600;
    color: black;
    text-transform: uppercase;
  }
  table {
    margin-top: -1px;
  }
`;

const JobTitleColumn = styled.th`
  width: 40%;
`;

const DepartmentColumn = styled.th`
  width: 20%;
`;

const LocationColumn = styled.th`
  width: 40%;
`;

const ButtonColumn = styled.th``;

const StyledIcon = styled.img`
  height: 14px;
  display: block;
  max-width: none;
`;

const OfferTitle = styled.div`
  font-weight: 600;
  color: #242d41;
`;

const FullLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
`;

class CareersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: []
    };
  }

  componentDidMount() {
    fetch("https://trustflight.recruitee.com/api/offers/")
      .then(res => res.json())
      .then(result => {
        this.setState({
          jobs: {
            offers: result.offers.sort((a, b) =>
              a.department > b.department
                ? 1
                : b.department > a.department
                ? -1
                : 0
            )
          }
        });
      });
  }

  renderTableBody = () => {
    const { jobs } = this.state;
    const { selection } = this.props;
    if (!jobs.offers) return;
    if (selection === "all") {
      return jobs.offers.map(offer => {
        if (offer.length === null) {
          return <td colSpan="3">No offers found</td>;
        } else {
          return (
            <tr key={offer.id}>
              <td data-title="Job Title">
                <FullLink
                  href={offer.careers_url}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                <OfferTitle>{offer.title}</OfferTitle>
              </td>
              <td data-title="Department">
                <FullLink
                  href={offer.careers_url}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                {offer.department}
              </td>
              <td data-title="Location">
                <FullLink
                  href={offer.careers_url}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                {offer.city}, {offer.country}
              </td>
              <td>
                <FullLink
                  href={offer.careers_url}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                <StyledIcon src={GoArrow} className={styles.goIcon} />
                <Button variant="link" className={styles.seeMoreButton}>
                  See more{" "}
                  <StyledIcon src={GoArrow} className={styles.goIcon} />
                </Button>
              </td>
            </tr>
          );
        }
      });
    } else {
      const filteredOffers = jobs.offers.reduce(function(filtered, offer) {
        if (offer.city === selection) {
          const tableRow = (
            <tr key={offer.id}>
              <td data-title="Job Title">
                <FullLink
                  href={offer.careers_url}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                <OfferTitle>{offer.title}</OfferTitle>
              </td>
              <td data-title="Department">
                <FullLink
                  href={offer.careers_url}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                {offer.department}
              </td>
              <td data-title="Location">
                <FullLink
                  href={offer.careers_url}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                {offer.city}, {offer.country}
              </td>
              <td>
                <FullLink
                  href={offer.careers_url}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                <StyledIcon src={GoArrow} className={styles.goIcon} />
                <Button variant="link" className={styles.seeMoreButton}>
                  See more{" "}
                  <StyledIcon src={GoArrow} className={styles.goIcon} />
                </Button>
              </td>
            </tr>
          );
          filtered.push(tableRow);
        }
        return filtered;
      }, []);
      if (filteredOffers.length === 0) {
        return (
          <td colSpan={3} align="center">
            No offers found
          </td>
        );
      } else {
        return filteredOffers;
      }
    }
  };

  render() {
    return (
      <TableDiv>
        <table className={`table-hover ${styles.jobTable}`}>
          <thead>
            <tr>
              <JobTitleColumn>Job Title</JobTitleColumn>
              <DepartmentColumn>Department</DepartmentColumn>
              <LocationColumn>Location</LocationColumn>
              <ButtonColumn></ButtonColumn>
            </tr>
          </thead>
          <tbody>{this.renderTableBody()}</tbody>
        </table>
      </TableDiv>
    );
  }
}

export default CareersTable;
